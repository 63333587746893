import LeaderBoardTable from "./tables";

const Dashboard = () => {
  return (
    <div className="flex flex-col">
      <div className="text-xl mb-5">Arbitrum Trader Stats</div>
      <LeaderBoardTable />
    </div>
  );
};

export default Dashboard;
