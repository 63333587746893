import { gql } from "@apollo/client";
import { getSubgraphClient } from "lib/utils/subgraph";
import { GMXV2_URL } from "lib/config/env";
import { useContext, createContext } from "react";
import { parseUnits } from "ethers";
import useSWR from "swr";
import { GmxV2AccountStatsResponseType } from "lib/types";

type GmxV2ContextType = {
  gmxV2Data: GmxV2AccountStatsResponseType[];
  isLoading: boolean;
  error: string;
};

export const GmxV2Data = createContext<GmxV2ContextType>({
  gmxV2Data: [],
  isLoading: false,
  error: "",
});

export const useGmxV2Data = () => useContext(GmxV2Data);

export const GmxV2DataContextProvider = ({ children }) => {
  const fetcher = async () => {
    const client = getSubgraphClient(GMXV2_URL);

    const MIN_COLLATERAL_USD_IN_LEADERBOARD = parseUnits("500", 30);

    const query = gql`
      query {
        periodAccountStats(limit: 100000, where: { maxCapital_gte: "${MIN_COLLATERAL_USD_IN_LEADERBOARD.toString()}"}) {
          id
          closedCount
          cumsumCollateral
          cumsumSize
          losses
          maxCapital
          realizedPriceImpact
          sumMaxSize
          netCapital
          realizedFees
          realizedPnl
          volume
          wins
          startUnrealizedPnl
          startUnrealizedFees
          startUnrealizedPriceImpact
        }
    }
    `;

    const { data } = await client!.query({ query });

    return {
      gmxV2Data: data?.periodAccountStats,
    };
  };

  const { data, error, isLoading } = useSWR("GmxV2Data", fetcher, {
    refreshInterval: 30000,
  });

  return (
    <GmxV2Data.Provider
      value={{
        gmxV2Data: data?.gmxV2Data,
        isLoading,
        error,
      }}
    >
      {children}
    </GmxV2Data.Provider>
  );
};
