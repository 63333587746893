import { Table } from "antd";
import { useGmxV2Data } from "lib/context";
import { formatValue, compare } from "lib/utils/helper";
import { Spin } from "antd";
import { Input } from "antd";
import { useState } from "react";

const { Search } = Input;

const GmxV2Table = () => {
  const { gmxV2Data, isLoading } = useGmxV2Data();
  const [searchString, setSearchString] = useState("");

  const columns = [
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      sorter: (a, b) => compare(a.rank, b.rank),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "PNL($)",
      dataIndex: "qualifyingPnl",
      key: "qualifyingPnl",
      render: (qualifyingPnl: bigint) =>
        qualifyingPnl > 0n ? (
          <span className="text-green-700">${formatValue(qualifyingPnl)}</span>
        ) : (
          <span className="text-red-700">${formatValue(qualifyingPnl)}</span>
        ),
      sorter: (a, b) => compare(a.qualifyingPnl, b.qualifyingPnl),
    },
    {
      title: "PNL(%)",
      dataIndex: "pnlPercent",
      key: "pnlPercent",
      render: (pnlPercent: bigint) =>
        pnlPercent > 0n ? (
          <span className="text-green-700">{formatValue(pnlPercent, 2, 2)}%</span>
        ) : (
          <span className="text-red-700">{formatValue(pnlPercent, 2, 2)}%</span>
        ),
      sorter: (a, b) => compare(a.pnlPercent, b.pnlPercent),
    },
    {
      title: "Avg Size",
      dataIndex: "avgSize",
      key: "avgSize",
      render: (avgSize: bigint) => `$${formatValue(avgSize)}`,
      sorter: (a, b) => compare(a.avgSize, b.avgSize),
    },
    {
      title: "Avg Lev",
      dataIndex: "avgLev",
      key: "avgLev",
      render: (avgLev: bigint) => `${formatValue(avgLev, 4, 2)}x`,
      sorter: (a, b) => compare(a.avgLev, b.avgLev),
    },
    {
      title: "Win/Loss",
      dataIndex: "winLoss",
      key: "winLoss",
    },
  ];

  const data = gmxV2Data
    ? gmxV2Data.map((account) => {
        const totalPnl: bigint = BigInt(account.realizedPnl) - BigInt(account.startUnrealizedPnl);
        const totalFees: bigint = BigInt(account.realizedFees) - BigInt(account.startUnrealizedFees);

        const qualifyingPnl = totalPnl - totalFees + BigInt(account.realizedPriceImpact);

        let pnlPercent: bigint = 0n;
        let avgSize: bigint = 0n;
        let avgLev: bigint = 0n;

        if (BigInt(account.maxCapital) > 0n) {
          pnlPercent = (qualifyingPnl * 10000n) / BigInt(account.maxCapital);
        }

        if (account.closedCount > 0) {
          avgSize = BigInt(account.sumMaxSize) / BigInt(account.closedCount);
        }

        if (BigInt(account.cumsumCollateral) > 0n) {
          avgLev = (BigInt(account.cumsumSize) * 10000n) / BigInt(account.cumsumCollateral);
        }

        return {
          address: account.id,
          qualifyingPnl,
          pnlPercent,
          avgSize,
          avgLev,
          winLoss: `${account.wins}/${account.losses}`,
        };
      })
    : [];

  const sortedData = data
    .sort((a, b) => (b.qualifyingPnl - a.qualifyingPnl > 0n ? 1 : -1))
    .map((account, index) => {
      return {
        key: index,
        rank: index + 1,
        ...account,
      };
    })
    .filter((acc) => acc.address.toLowerCase().includes(searchString.toLowerCase()));

  return (
    <div className="flex flex-col">
      {isLoading ? (
        <div className="flex flex-col gap-6 items-center justify-center h-[50vh]">
          <Spin size="large" />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <Search
            placeholder="Search address"
            onSearch={(value) => setSearchString(value)}
            onChange={(e) => setSearchString(e.target.value)}
            style={{ width: 420 }}
          />
          <Table columns={columns} dataSource={sortedData} pagination={{ pageSize: 30 }} />
        </div>
      )}
    </div>
  );
};

export default GmxV2Table;
