import { gql } from "@apollo/client";
import { getSubgraphClient } from "lib/utils/subgraph";
import { GMXV1_URL } from "lib/config/env";
import { useContext, createContext } from "react";
import useSWR from "swr";
import { GmxV1AccountStatsResponseType } from "lib/types";

type GmxV1ContextType = {
  gmxV1Data: GmxV1AccountStatsResponseType[];
  isLoading: boolean;
  error: string;
};

export const GmxV1Data = createContext<GmxV1ContextType>({
  gmxV1Data: [],
  isLoading: false,
  error: "",
});

export const useGmxV1Data = () => useContext(GmxV1Data);

export const GmxV1DataContextProvider = ({ children }) => {
  const fetcher = async () => {
    const client = getSubgraphClient(GMXV1_URL);

    const getUserInfoQuery = (
      skip: number
    ) => `userInfos(first: 1000, skip: ${skip}, orderBy: pnl, orderDirection: desc) {
          account
          pnl
          pnlPercent
          totalTradeSize
          avgLeverage
          avgTradeSize
          wins
          lose
        }`;

    const query = gql`
      query {
        userInfo0: ${getUserInfoQuery(0)}
        userInfo1: ${getUserInfoQuery(1000)}
        userInfo2: ${getUserInfoQuery(2000)}
        userInfo3: ${getUserInfoQuery(3000)}
        userInfo4: ${getUserInfoQuery(4000)}
        userInfo5: ${getUserInfoQuery(5000)}
      }
  `;

    const { data } = await client!.query({ query });

    const userInfos = [
      ...data?.userInfo0,
      ...data?.userInfo1,
      ...data?.userInfo2,
      ...data?.userInfo3,
      ...data?.userInfo4,
      ...data?.userInfo5,
    ];

    return {
      gmxV1Data: userInfos,
    };
  };

  const { data, error, isLoading } = useSWR("GmxV1Data", fetcher, {
    refreshInterval: 30000,
  });

  return (
    <GmxV1Data.Provider
      value={{
        gmxV1Data: data?.gmxV1Data ?? [],
        isLoading,
        error,
      }}
    >
      {children}
    </GmxV1Data.Provider>
  );
};
