import { gql } from "@apollo/client";
import { getSubgraphClient } from "lib/utils/subgraph";
import { GTRADE_URL } from "lib/config/env";
import { useContext, createContext } from "react";
import useSWR from "swr";
import { GTradeAccountStatsResponseType } from "lib/types";

type GTradeContextType = {
  gTradeData: GTradeAccountStatsResponseType[];
  isLoading: boolean;
  error: string;
};

export const GTradeData = createContext<GTradeContextType>({
  gTradeData: [],
  isLoading: false,
  error: "",
});

export const useGTradeData = () => useContext(GTradeData);

export const GTradeDataContextProvider = ({ children }) => {
  const fetcher = async () => {
    const client = getSubgraphClient(GTRADE_URL);

    const getTradingStatsRecordsQuery = (
      skip: number
    ) => `tradingStatsRecords(first: 1000, skip: ${skip}, orderBy: totalPnl, orderDirection: desc) {
          id
          address
          totalVolume
          totalPnl
          avgLeverage
          avgTradeSize
          wins
          loses
          totalPnlPercentage 
        }`;

    const query = gql`
      query {
        tradingStats0: ${getTradingStatsRecordsQuery(0)}
        tradingStats1: ${getTradingStatsRecordsQuery(1000)}
        tradingStats2: ${getTradingStatsRecordsQuery(2000)}
        tradingStats3: ${getTradingStatsRecordsQuery(3000)}
        tradingStats4: ${getTradingStatsRecordsQuery(4000)}
        tradingStats5: ${getTradingStatsRecordsQuery(5000)}
      }
  `;

    const { data } = await client!.query({ query });

    const tradingStats = [
      ...data?.tradingStats0,
      ...data?.tradingStats1,
      ...data?.tradingStats2,
      ...data?.tradingStats3,
      ...data?.tradingStats4,
      ...data?.tradingStats5,
    ];

    return {
      gTradeData: tradingStats,
    };
  };

  const { data, error, isLoading } = useSWR("GTradeData", fetcher, {
    refreshInterval: 30000,
  });

  return (
    <GTradeData.Provider
      value={{
        gTradeData: data?.gTradeData ?? [],
        isLoading,
        error,
      }}
    >
      {children}
    </GTradeData.Provider>
  );
};
