import { VELA_API_URL } from "lib/config/env";
import { useContext, createContext } from "react";
import useSWR from "swr";
import { VelaAccountStatsResponseType } from "lib/types";
import axios from "axios";

type VelaContextType = {
  velaData: VelaAccountStatsResponseType[];
  isLoading: boolean;
  error: string;
};

export const VelaData = createContext<VelaContextType>({
  velaData: [],
  isLoading: false,
  error: "",
});

export const useVelaData = () => useContext(VelaData);

export const VelaDataContextProvider = ({ children }) => {
  const fetcher = async () => {
    const res = await axios.get(
      VELA_API_URL +
        "leaderboard/users/paginated_stats/42161?start_ts=1682901750&end_ts=1717553650&p_category=any&p_index_token=any&direction=any&min_leverage=1&sort_ascending=true&sort_by=any"
    );

    return {
      velaData: res.data,
    };
  };

  const { data, error, isLoading } = useSWR("VelaData", fetcher, {
    refreshInterval: 30000,
  });

  return (
    <VelaData.Provider
      value={{
        velaData: data?.velaData,
        isLoading,
        error,
      }}
    >
      {children}
    </VelaData.Provider>
  );
};
