import { Tabs } from "antd";
import GmxV2Table from "./GmxV2Table";
import VelaTable from "./VelaTable";
import GmxV1Table from "./GmxV1Table";
import GTradeTable from "./GTradeTable";

const LeaderBoardTable = () => {
  return (
    <div className="flex flex-col gap-4">
      <Tabs
        defaultActiveKey="gmxv1"
        items={[
          {
            label: "GMX V1",
            key: "gmxv1",
            children: <GmxV1Table />,
          },
          {
            label: "GMX V2",
            key: "gmxv2",
            children: <GmxV2Table />,
          },
          {
            label: "Vela Exchange",
            key: "vela",
            children: <VelaTable />,
          },
          {
            label: "gTrade",
            key: "gTrade",
            children: <GTradeTable />,
          },
        ]}
      />
    </div>
  );
};

export default LeaderBoardTable;
