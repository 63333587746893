import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import {
  GTradeDataContextProvider,
  GmxV1DataContextProvider,
  GmxV2DataContextProvider,
  VelaDataContextProvider,
} from "lib/context";

import BigNumber from "bignumber.js";
import Layout from "layout";
import Dashboard from "pages/Dashboard";
import { StrictMode } from "react";

BigNumber.config({
  FORMAT: {
    prefix: "",
    decimalSeparator: ".",
    groupSeparator: ",",
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: " ",
    fractionGroupSize: 0,
    suffix: "",
  },
});

const App = () => {
  return (
    <GmxV2DataContextProvider>
      <GmxV1DataContextProvider>
        <VelaDataContextProvider>
          <GTradeDataContextProvider>
            <StrictMode>
              <Router>
                <Routes>
                  <Route element={<Layout />}>
                    <Route index element={<Dashboard />} />
                  </Route>
                </Routes>
              </Router>
            </StrictMode>
          </GTradeDataContextProvider>
        </VelaDataContextProvider>
      </GmxV1DataContextProvider>
    </GmxV2DataContextProvider>
  );
};

export default App;
