import { Table } from "antd";
import { useVelaData } from "lib/context";
import { compare } from "lib/utils/helper";
import { Spin } from "antd";
import { Input } from "antd";
import { useState } from "react";

const { Search } = Input;

const VelaTable = () => {
  const { velaData, isLoading } = useVelaData();
  const [searchString, setSearchString] = useState("");

  const columns = [
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      sorter: (a, b) => compare(a.rank, b.rank),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "PNL($)",
      dataIndex: "qualifyingPnl",
      key: "qualifyingPnl",
      render: (qualifyingPnl) =>
        qualifyingPnl > 0n ? (
          <span className="text-green-700">${Number(qualifyingPnl).toFixed(2)}</span>
        ) : (
          <span className="text-red-700">${Number(qualifyingPnl).toFixed(2)}</span>
        ),
      sorter: (a, b) => compare(a.qualifyingPnl, b.qualifyingPnl),
    },
    {
      title: "PNL(%)",
      dataIndex: "pnlPercent",
      key: "pnlPercent",
      render: (pnlPercent) =>
        pnlPercent > 0n ? (
          <span className="text-green-700">{Number(pnlPercent).toFixed(2)}%</span>
        ) : (
          <span className="text-red-700">{Number(pnlPercent).toFixed(2)}%</span>
        ),
      sorter: (a, b) => compare(a.pnlPercent, b.pnlPercent),
    },
    {
      title: "Avg Size",
      dataIndex: "avgSize",
      key: "avgSize",
      render: (avgSize: bigint) => `$${Number(avgSize).toFixed(2)}`,
      sorter: (a, b) => compare(a.avgSize, b.avgSize),
    },
    {
      title: "Avg Lev",
      dataIndex: "avgLev",
      key: "avgLev",
      render: (avgLev) => `${avgLev}x`,
      sorter: (a, b) => compare(a.avgLev, b.avgLev),
    },
    {
      title: "Biggest Win",
      dataIndex: "bigWin",
      key: "bigWin",
      render: (bigWin) => <span className="text-green-700">${Number(bigWin).toFixed(2)}</span>,
      sorter: (a, b) => compare(a.bigWin, b.bigWin),
    },
    {
      title: "Win/Loss",
      dataIndex: "winLoss",
      key: "winLoss",
    },
  ];

  const data = velaData
    ? velaData
        .map((account) => {
          const pnlPercent = (account.realised_pnl * 100) / account.volume;
          const avgSize = account.volume / account.n_trades;

          return {
            rank: account.rank,
            key: account.account,
            address: account.account,
            qualifyingPnl: account.realised_pnl,
            pnlPercent,
            avgSize,
            avgLev: account.avg_leverage,
            bigWin: account.biggest_win,
            winLoss: `${account.wins}/${account.losses}`,
          };
        })
        .filter((acc) => acc.address.toLowerCase().includes(searchString.toLowerCase()))
    : [];

  return (
    <div className="flex flex-col">
      {isLoading ? (
        <div className="flex flex-col gap-6 items-center justify-center h-[50vh]">
          <Spin size="large" />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <Search
            placeholder="Search address"
            onSearch={(value) => setSearchString(value)}
            onChange={(e) => setSearchString(e.target.value)}
            style={{ width: 420 }}
          />
          <Table columns={columns} dataSource={data} pagination={{ pageSize: 30 }} />
        </div>
      )}
    </div>
  );
};

export default VelaTable;
