import { ApolloClient, InMemoryCache } from "@apollo/client";

export const getSubgraphClient = (url: string) => {
  return new ApolloClient({
    uri: url,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
      },
    },
  });
};
