import { Outlet } from "react-router-dom";
import { useMedia } from "react-use";
import cx from "classnames";

const Layout = () => {
  const isMobile = useMedia("(max-width: 450px)");

  return (
    <>
      <main className={cx("pt-10", isMobile ? "mb-8" : "mb-[40px]")}>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
